import { Component, createEffect } from 'solid-js'
import styles from '~/components/layout/layout.module.scss'
import { LayoutProps } from '~/components/layout/layout.interfaces'
import DebugOverlay from '~/components/debug-overlay/debug-overlay'
import { supabase } from '~/services/supabase'
import { useIsRouting, useLocation, useNavigate } from '@solidjs/router'
import LoadingOverlay from '~/components/loading-overlay/loading-overlay'
import { loadingState } from '~/services/loading/loading'
import { getUserSettings } from '~/services/database/users'
import Footer from '~/components/footer/footer'

const Layout: Component<LayoutProps> = ({ children, center, landing }) => {
  const navigate = useNavigate()
  const isRouting = useIsRouting()
  const location = useLocation()
  const loggedOutPages = [
    '/account/login',
    '/account/register',
    '/account/request-password-reset',
    '/account/reset-password',
    '/debug/export-modal',
    '/documentation/translation-import',
  ]

  createEffect(async () => {
    const isLoggedIn = Boolean((await supabase.auth.getSession()).data.session)
    const isOnLoggedOutPage = loggedOutPages.includes(location.pathname)
    const userSettings = await getUserSettings()
    const isFirstLogin = !userSettings?.onboardingComplete
    if(!isLoggedIn && !isOnLoggedOutPage){
      navigate('/account/login')
    }
    if(isLoggedIn && isFirstLogin){
      navigate('/account/onboarding', {
        state: {
          mustCreatePassword: Boolean(userSettings?.mustCreatePassword)
        }
      })
    }
  })

  const classList = {
    [styles.container]: true,
    [styles.center]: center,
    [styles.landing]: landing
  }

  return (
    <main classList={classList}>
      <DebugOverlay />
      <LoadingOverlay
        show={loadingState().loading || isRouting()}
        message={loadingState().message}
      />
      {children}
      <Footer />
    </main>
  )
}

export default Layout