import { Component, createSignal, onMount } from 'solid-js'
import Button from '~/components/button/button'
import styles from '~/components/debug-overlay/debug-overlay.module.scss'
import { history } from '~/editor/history'
import Action from '~/editor/history/action'
import { translations } from '~/services/current-document/translations'
import { selectedTypesetTexts, typesetTexts, updateTypesetTextInDB } from '~/services/current-document/typeset-texts'
import { settings, updateSettings } from '~/services/settings/settings'

const DebugOverlay: Component = () => {
  const [isVisible, setIsVisible] = createSignal(false)
  const classList = () => ({
    [`${styles.container}`]: true,
    [`${styles.isVisible}`]: isVisible()
  })
  onMount(() => {
    window.addEventListener('keydown', (event: KeyboardEvent) => {
     if(event.altKey && event.code === 'KeyD'){
      setIsVisible(isVisible => !isVisible)
     }
     if(event.altKey && event.code === 'KeyQ'){
       updateSettings({
         darkmode: !settings().darkmode
       })
     }
    })
  })

  // History
  const onHistoryList = () => {
    console.info({
      actions: history.actions,
      index: history.index
    })
  }
  const onActionAdd = () => {
    history.append(new Action({
      name: 'testAction',
      undo: () => console.info('undo'),
      redo: () => console.info('redo')
    }))
  }
  const onUndo = () => {
    history.undo()
  }
  const onRedo = () => {
    history.redo()
  }

  // Translations
  const onTranslationDetails = () => {
    const entry = translations().find(entry => entry.highlighted)
    console.info(entry ?? 'No entry highlighted')
  }
  const onTranslationList = () => {
    console.info(translations())
  }

  // TypesetTexts
  const onTypesetTextDetails = () => {
    const entries = selectedTypesetTexts()
    console.info(...entries)
  }
  const onTypesetTextList = () => {
    typesetTexts().forEach((entry) => {
      console.info(entry)
    })
  }
  const onSaveTypesetTexts = () => {
    const type = selectedTypesetTexts()[0]
    if(type){
      type.onChange()
      updateTypesetTextInDB(type)
    }
  }
  const onDump = () => {
    const type = selectedTypesetTexts()[0]
    if(type){
      console.log({
        textObject_width: type.textObject.getScaledWidth(),
        strokeObject_width: type.strokes[0].getScaledWidth()
      })
    }
  }
  const onDeleteTypesetTexts = () => {
    typesetTexts().forEach(entry => entry.onDelete())
  }

  return (
    <div classList={classList()}>
      <div class={styles.section}>
        <div class={styles.title}>History</div>
        <Button onClick={onHistoryList}>List History</Button>
        <Button onClick={onActionAdd}>Add action to History</Button>
        <Button onClick={onUndo}>Undo</Button>
        <Button onClick={onRedo}>Redo</Button>
      </div>
      <div class={styles.section}>
        <div class={styles.title}>Translations</div>
        <Button onClick={onTranslationDetails}>📖 Entry Details</Button>
        <Button onClick={onTranslationList}>📖 List Translations</Button>
        <div class={styles.title}>TypesetTexts</div>
        <Button onClick={onTypesetTextDetails}>✒️ Entry Details</Button>
        <Button onClick={onTypesetTextList}>✒️ List TypesetTexts</Button>
        <Button onClick={onSaveTypesetTexts}>✒️ Save Selected</Button>
        <Button onClick={onDump}>✒️ Dump details</Button>
        <Button onClick={onDeleteTypesetTexts}>✒️ Delete all</Button>
      </div>
    </div>
  )
}

export default DebugOverlay
