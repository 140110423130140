import { Component } from 'solid-js'
import { FatalErrorProps } from '~/components/fatal-error/fatal-error.interfaces'
import styles from '~/components/fatal-error/fatal-error.module.scss'
import BrandLogo from '~/assets/brand/logo-with-tagline.svg'
import { useNavigate } from '@solidjs/router'
import { translations } from '~/translations'

const FatalError: Component<FatalErrorProps> = ({ error }) => {
  console.trace(error)
  const navigate = useNavigate()
  const onClick = () => {
    navigate('/')
  }

  return (
    <div class={styles.fatalError}>
      <div class={styles.container}>
        <img
          src='/assets/images/illustration-octopus.png'
          alt=''
          class={styles.illustration}
        />

        <div class={styles.text}>
          <BrandLogo class={styles.logo} />
          <div class={styles.title}>
            {translations().errors.fatalError.title}
          </div>
          <p class={styles.description}>
            {translations().errors.fatalError.description}
          </p>
          <button
            class={styles.reset}
            onClick={onClick}
          >
            {translations().errors.fatalError.back}
          </button>
        </div>
      </div>
    </div>
  )
}

export default FatalError
