import { Component } from 'solid-js'
import { LoadingOverlayProps } from '~/components/loading-overlay/loading-overlay.interfaces'
import styles from '~/components/loading-overlay/loading-overlay.module.scss'
import { translations } from '~/translations'

const LoadingOverlay: Component<LoadingOverlayProps> = ({ show, message }) => {
  const classList = () => ({
    [`${styles.loadingOverlay}`]: true,
    [`${styles.show}`]: show
  })

  const loadingMessage = () => message ?? translations().loading.default

  return (
    <div classList={classList()}>
      <div class={styles.animationContainer}>
        <div class={styles.animation}>
          <img class={styles.charaA} src='/assets/images/loading-a.png' alt='' />
          <div class={styles.charaAShadow} />
          <img class={styles.charaB} src='/assets/images/loading-b.png' alt='' />
          <div class={styles.charaBShadow} />
        </div>
      </div>
      <div class={styles.label}>
        {loadingMessage()}
      </div>
    </div>
  )
}

export default LoadingOverlay
